<template>
  <v-data-table
    :headers="$vuetify.breakpoint.xs ? headersXs : headers"
    :items="items"
    :server-items-length="total"
    :options.sync="options"
    :loading="loading"
    class="elevation-1 align-self-start col-12 pa-0"
    item-key="id"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Proveedores</v-toolbar-title>

        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>

        <v-dialog v-model="dialog" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">
                <v-badge
                  :color="item.Activo ? 'success' : 'error'"
                  dot
                >
                  {{ item.RazonSocial }}
                </v-badge>
              </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 300px;">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.NroCuenta }}</v-list-item-title>
                    <v-list-item-subtitle>Nro. de Cuenta</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.TipoDoc }} {{ item.NroDoc }}</v-list-item-title>
                    <v-list-item-subtitle>Documento</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.categoriaIva }}</v-list-item-title>
                    <v-list-item-subtitle>Categoría IVA</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.IngresosBrutos }}</v-list-item-title>
                    <v-list-item-subtitle>Ingresos Brutos</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeItem">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogContacto" scrollable max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ item.RazonSocial }}</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 300px;">
              <v-list two-line subheader>
                <v-subheader>Contacto</v-subheader>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-cellphone</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Celular }}</v-list-item-title>
                    <v-list-item-subtitle>Celular</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-phone</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Telefono }}</v-list-item-title>
                    <v-list-item-subtitle>Fijo</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-email</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Email }}</v-list-item-title>
                    <v-list-item-subtitle>E-Mail</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">mdi-map-marker</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.Calle }} {{ item.Nro }} {{ item.Piso }} {{ item.Depto }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.Localidad }}, {{ item.Provincia }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeContacto">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.NroDoc="{ item }" class="text-right">
      {{ item.TipoDoc }} {{ item.NroDoc }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        color="info"
        class="ml-4"
        @click="openItem(item)"
        aria-label="Detalle"
        title="Detalle"
      >
        mdi-card-account-details
      </v-icon>
      <v-icon
        color="info"
        class="ml-4"
        @click="openContacto(item)"
        aria-label="Datos de Contacto"
        title="Datos de Contacto"
      >
        mdi-card-account-phone
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>

import _ from 'lodash'

export default {
  data: () => ({
    loading: false,
    dialog: false,
    dialogContacto: false,
    search: '',
    headersXs: [
      { text: 'Razón Social', value: 'RazonSocial', sortable: false },
      { text: 'Acciones', value: 'action', sortable: false, align: 'right' },
    ],
    headers: [
      { text: 'ID', value: 'Id', align: 'left', sortable: false },
      { text: 'Razón Social', value: 'RazonSocial', sortable: false },
      { text: 'Categoría IVA', value: 'categoriaIva', sortable: false },
      { text: 'Documento', value: 'NroDoc', align: 'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    filtro: {
      desde: null,
      hasta: null,
      pagina_solicitada: 1,
      registrosPorPagina: 10,
    },
    item: {},
    itemDefault: {
      Email: '',
      Activo: false,
      Depto: '',
      Nro: '',
      categoriaIva: '',
      Localidad: '',
      Provincia: '',
      Piso: '',
      Celular: '',
      TipoDoc: '',
      IngresosBrutos: '',
      Telefono: '',
      RazonSocial: '',
      Calle: '',
      Id: null,
      NroCuenta: null,
      NroDoc: '',
    },
  }),

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    search: function () {
      this.debounceRefresh()
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true

      this.filtro.consulta = this.search
      this.filtro.pagina_solicitada = this.options.page
      this.filtro.registrosPorPagina = this.options.itemsPerPage

      this.$soapClient.call('listarProveedores', {
        filtro: this.filtro,
      })
      .then((response) => {
        this.items = response.data.resultado
        this.total = response.data.filtros.totalRegistros
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    openItem (item) {
      this.loading = true

      this.$soapClient.call('getProveedor', {
        id: item.Id,
      })
      .then((response) => {
        this.item = response.data.resultado
        this.dialog = true
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    closeItem () {
      this.dialog = false
      this.item = {...this.itemDefault}
    },

    openContacto (item) {
      this.loading = true

      this.$soapClient.call('getProveedor', {
        id: item.Id,
      })
      .then((response) => {
        this.item = response.data.resultado
        this.dialogContacto = true
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    closeContacto () {
      this.dialogContacto = false
      this.item = {...this.itemDefault}
    },
  },
};
</script>

<style scoped>
  >>> .v-data-table-header-mobile {
    display: none;
  }
</style>
